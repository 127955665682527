import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deload Week`}</p>
    <p>{`Single Leg Squats 3×12/leg (trailing leg on box or bench)`}</p>
    <p>{`Single Leg RDL’s 3×6/leg`}</p>
    <p>{`then, For time:`}</p>
    <p>{`140ft x 3 Sled Push (+90/45`}{`#`}{`)`}<em parentName="p">{`(down and back x3 on turf)`}</em></p>
    <p>{`30-V Ups`}</p>
    <p>{`40-Squats Holding Bumper Plate (45/25`}{`#`}{`)`}</p>
    <p>{`40-Situps`}</p>
    <p>{`50-Jumps Over Bumper Plate (45/25`}{`#`}{`)`}</p>
    <p>{`50-KBS’s (53/35)`}</p>
    <p>{`*`}{`compare time `}<em parentName="p">{`to 9/10/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      